import React, { useState } from "react";
import style from "./ClientsTestimonialsImages.module.scss";
import YoutubePopup from "../../elements/YoutubePopup/YoutubePopup";

const ClientsTestimonialsImages = () => {
  const [youtubeOpen, setYoutubeOpen] = useState(false);
  return (
    <div className={style.wrapper}>
      {/* eslint-disable-next-line */}
      <div className={style.client}>
        <img src='/testimonials/1.png' alt='' />
      </div>
      <div className={style.client}>
        <img src='/testimonials/2.png' alt='' />
      </div>
      <div className={style.client}>
        <img src='/testimonials/3.png' alt='' />
      </div>
      <div className={style.client}>
        <img src='/testimonials/4.png' alt='' />
      </div>{/* 
      <div className={style.client}>
        <img src='/testimonials/5.png' alt='' />
      </div> */}

      <div className={style.background} />
      {/* eslint-disable-next-line */}
      <div className={style.playButton} onClick={() => setYoutubeOpen(true)} />
      <YoutubePopup code='Ewe2TmJlS9U' open={youtubeOpen} closeHandle={() => setYoutubeOpen(false)} />
    </div>
  );
};

export default ClientsTestimonialsImages;
