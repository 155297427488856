import React from "react";
import cx from "classnames";
import style from "./Numbers.module.scss";
import Shape from "../../elements/Shape/Shape";



const Numbers = ({ icon, text = <>Abbiamo generato più di 150000 leads.<br /> Di successo e certificati</>, children }) => {
  const numbers = React.Children.map(children, (child, index) => React.cloneElement(child, { ...child, index: index }));
  return (
    <section className={style.numbers}>
      <div className="relative">
        <Shape direction='inverse' position="sticky" />
        <div className='container'>
          {icon && (
            <div className={cx("row", style.icon_wrapper)}>
              <div className={cx(style.icon, style[icon])} />
            </div>
          )}
          <div className='row'>
            <div className='col-12 col-lg-10 offset-lg-1'>
              <h3>
                {text}
              </h3>
            </div>
          </div>
          <div className={cx("row mt-16", style.numbers_row)}>{numbers}</div>
        </div>
      </div>
    </section>
  );
};

export const Number = ({ number, text, index }) => (
  <div className={cx("col-12 col-md-4", style.column, { "mt-1 mt-md-0": index !== 0 })}>
    <h3 className={style.column_number}>{number}</h3>
    <div className={style.column_text}>{text}</div>
  </div>
);

export default Numbers;
