import React from "react";
import cx from "classnames";
import style from "./DoubleArrow.module.scss";

/* eslint-disable */
const DoubleArrow = ({className = "", leftCallback, rightCallback}) => (
  <div className={cx(style.arrows, className)}>
    <div className={cx(style.arrowLeft, {[style.cursor]: leftCallback})} onClick={leftCallback} />
    <div className={cx(style.arrowRight, {[style.cursor]: rightCallback})} onClick={rightCallback} />
  </div>
);

export default DoubleArrow;
