import React from "react";
import cx from "classnames";
import style from "./YoutubePopup.module.scss";

/* eslint-disable */
const YoutubePopup = ({ code, open = false, closeHandle }) => (
  <div className={cx(style.popup, { [style.open]: open })} onClick={() => closeHandle()}>
    <div className={style.wrapper}>
      <iframe title='youtube' type='text/html' width='640' height='360' src={`https://www.youtube.com/embed/${code}?enablejsapi=1&origin=http://example.com`} frameborder='0'></iframe>
      <div className={style.closeButton} onClick={() => closeHandle()} />
    </div>
  </div>
);

export default YoutubePopup;
