import React, {useState, useEffect} from "react";
import cx from "classnames";
import style from "./Testimonial.module.scss";
import Flickity from "react-flickity-component";
import DoubleArrow from "../../atoms/DoubleArrow/DoubleArrow";

export const TestimonialSlider = ({children}) => {
  const [ref, setRef] = useState(null);
  const [render, setRender] = useState(false);

  const slide = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      next: () => ref.next(),
      prev: () => ref.previous(),
    })
  );

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <section>
      {render && (
        <Flickity
          className={style.carousel}
          elementType={"div"}
          options={{prevNextButtons: false, pageDots: false, wrapAround: true, fullscreen: true}}
          disableImagesLoaded={false}
          reloadOnUpdate
          static
          flickityRef={c => setRef(c)}
        >
          {slide}
        </Flickity>
      )}
    </section>
  );
};

const Testimonial = ({name, children, position, backgroundUrl, next, prev, className = ""}) => (
  <div>
    {children}
    <div>
      <div className={cx(style.wrapper, className, "mt-3")}>
        <div className={style.image} style={{backgroundImage: `url(${backgroundUrl})`}} />
        <div className={style.texts}>
          <div className={style.name}>{name}</div>
          <div className={style.position}>{position}</div>
        </div>
      </div>
      <DoubleArrow className={"mt-4"} rightCallback={next} leftCallback={prev} />
    </div>
  </div>
);

export default Testimonial;
